<template>
	<div>
		<CRow>
			<CCol sm="12">
				<AddEditForm :headerTitle="$t('menu.detailAgent')" :onGoBack="onGoBack" :showDelete="false">
					<template v-slot:headerAddEdit>
						<button type="button" name="add-addSupplier" class="btn btn-primary" style="display:none">
							{{ $t('common.save') }}
						</button>
					</template>
					<template #content>
						<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingAgent != null">
							<template v-slot:body>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('common.name') }}:</label></b>
									</div>
									<div class="col-md-7">
										<p>{{ editingAgent.name }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('common.phone') }}:</label></b>
									</div>
									<div class="col-md-7">
										<p>{{ editingAgent.phone }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('common.address') }}:</label></b>
									</div>
									<div class="col-md-7">
										<p>{{ editingAgent.address }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('pages.agent.ownerName') }}:</label></b>
									</div>
									<div class="col-md-7">
										<p>{{ editingAgent.ownerName }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('common.subject') }}:</label></b>
									</div>
									<div class="col-md-7">
										<p>{{ editingAgent.subject }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<b><label>{{ $t('common.description') }}:</label></b>
									</div>
									<div class="col-md-7">
										<ckeditor v-model="editingAgent.description" :config="editorConfig"></ckeditor>
									</div>
								</div>
							</template>
						</Accordion>
					</template>
				</AddEditForm>
			</CCol>
		</CRow>
		<!-- Show product images -->
		<CRow>
			<CCol sm="12">
				<Accordion :headerTitle="$t('pages.agent.agentPictures')" v-if="editingAgent != null" expand>
					<template v-slot:body>
						<ImageListView :agentId="editingAgent.id" :items="editingAgent.pictures" :showUploadImage="false"></ImageListView>
					</template>
				</Accordion>
			</CCol>
		</CRow>
	</div>
	
</template>

<script>
    import i18n from '@/plugins/i18n'
	import { mapGetters } from 'vuex'
    import { globalConfigs } from '@/global/index'
    import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
    import ImageListView from '@/components/ImageListView.vue'   

	export default {
        name: 'DetailAgent',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				editorConfig: globalConfigs.CKEditorConfig,
                files: null,
			}
		},
        computed: {
            ...mapGetters('agent', ['allAgents', 'editingAgent', 'hasError', 'errorMessage']),
			...mapGetters('location', ['listProvinces', 'listDistricts', 'listWards']),           
		},
		components: {
			Accordion,
			AddEditForm,
            ImageListView
        },
		methods: {            
            onGoBack() {
                this.$router.push('/agents/list');
            },            			  
		},
		mounted() {			
            this.$store.dispatch("agent/detailAgent", this.$route.params.id);			        
		}
	}
</script>