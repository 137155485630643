<template>
    <div>
        <CDataTable :items="items"
                    :fields="fields">

            <template #agent_image="{item}">
                <td>
                    <img :src="item.thumbImageUrl" />
                </td>
            </template>                      
        </CDataTable>     
    </div>
</template>

<script>   
    import i18n from '@/plugins/i18n'
    export default {
        name: 'AgentImageList',
        props: {
            items: {
                default: null,
                type: Array
            },
            agentId: {
                default: null,
                type: Number
            },            
        },
        data() {
            return {
                files: null,
                fields: [                     
                    { key: "agent_image", label: i18n.t('pages.product.picture') }
                ],
            };
        },
        components: {           
        },
        methods: {           
        },
    }
</script>

<style scoped>
    header {
        /*background: #3c4b64*/
    }
    h5 {
        font-size: 1.09375rem;
    }
        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
